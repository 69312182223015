<template>
  <register-container>
    <template slot="header">{{
      pageTitle || $t('registration.registerAssessment.title')
    }}</template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <v-container>
      <div>
        <v-row no-gutters>
          <v-carousel cycle interval="10000" :show-arrows="false" class="carousel">
            <v-carousel-item
              v-bind:key="item.key"
              v-for="item in carouselImages"
              :src="require(`../images/${item.img}`)"
              class="carousel-image"
            >
              <div class="d-flex justify-center align-center carousel-text">
                <div class="carousel-upper-right-text ma-5 ma-sm-12">
                  {{ item.text.upperRight }}
                </div>
                <div class="carousel-upper-left-text ma-5 ma-sm-12">
                  {{ item.text.upperLeft }}
                </div>
                <div class="carousel-text-main-background d-flex justify-center">
                  <div class="carousel-text-main text-center py-5 py-sm-10">
                    {{ item.text.main }}
                  </div>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-row>
      </div>
    </v-container>
    <div class="mt-12 d-flex justify-center">
      <v-btn
        depressed
        class="complete-button pa-5 font-weight-bold"
        :class="completeHover ? 'secondary text--white' : 'secondary--text'"
        x-large
        @click="nextRoute()"
        @mouseover="completeHover = true"
        @mouseleave="completeHover = null"
        >{{ $t('registration.registerAssessment.complete') }}</v-btn
      >
    </div>
  </register-container>
</template>

<style lang="scss">
.carousel {
  border-radius: 8px;
  border: 3px solid var(--v-secondary-base);
  .v-carousel__controls {
    background-color: var(--v-secondary-base) !important;
    opacity: 0.8;
    height: 70px;
  }
}
.carousel-text {
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel-image {
  .v-image {
    .v-image__image {
      background-position: 0% 5% !important;
    }
  }
}
.carousel-upper-left-text {
  position: absolute;
  top: 0;
  left: 75px;
  font-size: 2em;
  color: black;
  text-transform: capitalize !important;
}
.carousel-upper-right-text {
  @extend .carousel-upper-left-text;
  right: 100px;
  left: unset;
}
.carousel-text-main {
  max-width: 400px;
  font-size: 1.5em;
}
.carousel-text-main-background {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}
.complete-button {
  border: 1px solid var(--v-secondary-base);
  font-size: 1.1em;
  border-radius: 8px;
  background-color: white !important;
  height: 75px !important;
  width: 350px !important;
  max-width: 95% !important;
}

@media all and (max-width: 600px) {
  .carousel {
    height: 370px !important;
    .v-carousel__controls {
      .v-item-group {
        .v-carousel__controls__item {
          height: 15px !important;
          width: 15px !important;
        }
      }
    }
  }
  .carousel-image {
    .v-image {
      height: 370px !important;
      .v-image__image {
        background-size: cover !important;
        background-position: center top !important;
        height: 370px !important;
      }
    }
  }
  .carousel-text-main {
    max-width: 95%;
    font-size: 1em;
  }
  .carousel-upper-left-text {
    left: 0;
    font-size: 1.2em;
  }
  .carousel-upper-right-text {
    @extend .carousel-upper-left-text;
    left: unset;
    right: 0;
  }
}
</style>

<script>
import RegisterContainer from '../components/RegisterContainer.vue';

export default {
  name: 'RegisterAssessment',
  components: { RegisterContainer },
  data() {
    return {
      completeHover: null,
      carouselImages: [
        {
          key: 1,
          img: 'register-carousel-1.png',

          text: {
            main: "We know you're here. Please allow us up to 20 minutes to prepare your appointment  ",
            upperLeft: 'Welcome',
          },
        },
        {
          key: 2,
          img: 'register-carousel-2.png',

          text: {
            main: 'For calls and in-person appointments you may tap complete assessment',
            upperRight: 'Completing intake',
          },
        },
        {
          key: 3,
          img: 'register-carousel-3.png',

          text: {
            main: "If you selected video, we'll direct you to your assessment as soon as we're ready",
            upperRight: 'Notifying physician',
          },
        },
        {
          key: 4,
          img: 'register-carousel-4.png',

          text: {
            main: 'Sentry Health is driven to enrich and empower the lives of our patients and partners',
            upperLeft: 'complete care',
          },
        },
        {
          key: 5,
          img: 'register-carousel-5.png',

          text: {
            main: "A core focus on prevention, engagement and proactive health. We're just one message away",
            upperRight: 'message us below',
          },
        },
        {
          key: 6,
          img: 'register-carousel-6.png',

          text: {
            main: 'Psychiatry, cardiology, internal medicine, mental health and more. Just ask!',
            upperRight: 'family support',
          },
        },
      ],
    };
  },
  methods: {
    nextRoute() {
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
  },
};
</script>
